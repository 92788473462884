.property {
  margin: 10px;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.property:hover {
  transform: scale(1.05);
}

.property-image-container {
  width: 100%;
  overflow: hidden;
}

.property-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  object-fit: contain; /* Ensures the full image is visible */
}

.property:hover .property-image {
  transform: scale(1.1); /* Slight zoom on hover */
}

.property h2, .property p {
  text-align: center;
}

/* Styles for the PropertyPage image slider */
.property-page {
  text-align: center;
  padding: 20px;
}

.property-page-slider {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.image-container {
  width: 100%;
  height: 450px; /* Set a fixed height to ensure uniformity */
  overflow: hidden;
  position: relative;
}

.property-page-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the container, potentially cropping */
  border-radius: 8px;
}

.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.arrow-button.left {
  left: 10px;
}

.arrow-button.right {
  right: 10px;
}

