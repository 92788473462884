:root {
  --primary-color: #00a86b; /* Green */
  --secondary-color: #c71585; /* Magenta */
  --background-color: #f0f8f4;
  --text-color: #333;
  --light-text-color: #fff;
  --border-radius: 8px;
  --box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

p {
  margin-bottom: 1rem;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: var(--transition);
}

a:hover {
  color: var(--secondary-color);
}

/* Header */
header {
  background-color: #fff;
  box-shadow: var(--box-shadow);
  position: sticky;
  top: 0;
  z-index: 1000;
}

header .container {
  /* display: flex;*/
  justify-content: space-between;
  align-items: center;
  padding: 1rem 20px;
}

header .logo {
  flex: 1;
  /* display: flex; */
  align-items: center;
}

header .logo img {
  max-height: 150px;
  margin-right: 1rem;
}

header .title {
  flex: 2;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--primary-color);
}

header .contact-info {
  flex: 1;
  text-align: right;
}

header .contact-info p {
  margin: 0;
}

header .contact-info .whatsapp-button {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
}

header .contact-info .whatsapp-button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(199, 21, 133, 0.3);
}

/* Search and Filters */
.search-bar,
.filters-bar {
  background-color: #fff;
  padding: 2rem 0;
  box-shadow: var(--box-shadow);
  border: 1px solid #bb4e6e; /* Magenta border */
}

.search-bar .container,
.filters-bar .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.search-bar input,
.filters-bar select,
.search-bar button,
.filters-bar button {
  padding: 12px 20px;
  border: 2px solid #e0e0e0;
  border-radius: 25px;
  font-size: 1em;
  transition: var(--transition);
}

.search-bar input:focus,
.filters-bar select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(0, 168, 107, 0.2);
}

.search-bar button,
.filters-bar button {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  cursor: pointer;
  border: none;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.search-bar button:hover,
.filters-bar button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(199, 21, 133, 0.3);
}

/* Property Listings */
.property-listings {
  padding: 4rem 0;
}

.property-listings .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.property {
  background-color: #fff;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

.property:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.property-image-container {
  position: relative;
  overflow: hidden;
}

.property img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.property:hover img {
  transform: scale(1.05);
}

.property-image-container .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: var(--transition);
}

.property-image-container:hover .arrow {
  opacity: 1;
}

.property-image-container .arrow-left {
  left: 10px;
}

.property-image-container .arrow-right {
  right: 10px;
}

.property-content {
  padding: 1.5rem;
}

.property h2 {
  font-size: 1.4em;
  margin-bottom: 0.5rem;
}

.property p {
  color: #666;
  font-size: 0.9em;
}

/* Footer */
footer {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  padding: 3rem 0;
  text-align: center;
}

footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

footer a {
  color: var(--light-text-color);
}

footer a:hover {
  color: var(--secondary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  header .container {
    flex-direction: column;
    padding-top: 1rem;
  }

  header .logo {
    margin-bottom: 1rem;
  }

  header .logo img {
    max-height: 40px;
  }

  header .title {
    font-size: 1.4rem;
  }

  header .contact-info {
    margin-top: 1rem;
    text-align: center;
  }

  .property-listings .container {
    grid-template-columns: 1fr;
  }

  footer .container {
    flex-direction: column;
    gap: 1rem;
  }
}

/* General styling */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.App {
  text-align: center;
}

/* Header styling */
header {
  background-color: white;
  padding: 10px 0;
  border-bottom: 1px solid #bb4e6e;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.logo img {
  max-width: 200px;
}

.title {
  font-size: 1.5em;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
}

.contact-info {
  text-align: right;
}

.whatsapp-button {
  background-color: #25d366;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
  background-color: #1ebd56;
}

/* Search bar styling */
.search-bar {
  background-color: white;
  padding: 10px 0;
  border-bottom: 1px solid #bb4e6e;
}

.search-bar input {
  width: 80%;
  padding: 10px;
  border: 1px solid #bb4e6e;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Filters bar styling */
.filters-bar {
  background-color: white;
  padding: 10px 0;
  border-bottom: 1px solid #bb4e6e;
}

.filters-bar select {
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #bb4e6e;
  border-radius: 5px;
}

.filters-bar button {
  padding: 10px 20px;
  border: 1px solid #bb4e6e;
  background-color: #bb4e6e;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.filters-bar button:hover {
  background-color: #b03d5a;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Property listings styling */
.property-listings {
  padding: 20px 0;
}

.property-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.property-grid .property-card {
  width: 30%;
  margin: 10px;
  padding: 10px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.property-card img {
  width: 100%;
  height: auto;
}

.property-card h3 {
  margin: 10px 0 5px;
}

.property-card p {
  margin: 5px 0;
}

